import { Routes, Route } from "react-router-dom";
import { lazy } from "react";
import "react-toastify/dist/ReactToastify.css";
import withRouterValidation from "./utils/withRouterValidation";
import withSuspense from "./utils/withSuspense";
import TagManager, { DataLayerArgs } from "react-gtm-module";

const Pay = lazy(() => import("./views/Pay"));
const SuccessPlatform = lazy(() => import("./views/SuccessPlatform"));
const PendingPlatform = lazy(() => import("./views/PendingPlatform"));
const ErrorViewPlatform = lazy(() => import("./views/ErrorPlatform"));
const Success = lazy(() => import("./views/Success"));
const Pending = lazy(() => import("./views/Pending"));
const ErrorView = lazy(() => import("./views/Error"));
const FindInvoice = lazy(() => import("./views/FindInvoice"));
const NotFound = lazy(() => import("./views/NotFound"));

interface TagManagerArgs extends DataLayerArgs {
  // GTM id, must be something like GTM-000000.
  gtmId: string;
  // Additional events such as 'gtm.start': new Date().getTime(),event:'gtm.js'.
  events?: object | undefined;
  // Used to set environments.
  auth?: string | undefined;
  // Used to set environments, something like env-00.
  preview?: string | undefined;
}

const tagManagerArgs: TagManagerArgs = {
  gtmId: import.meta.env.VITE_APP_GTM_ID as string,
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  // const [showMaintananceMsg, setShowMaintananceMsg] = useState(false);

  // useEffect(() => {
  //   if (showMaintananceMsg) {
  //     const showReal = window?.location?.href
  //       ?.split("?")?.[1]
  //       ?.includes("test");
  //     setShowMaintananceMsg(!showReal);
  //   }
  // }, [showMaintananceMsg]);
  // if (showMaintananceMsg) {
  //   return (
  //     <Routes>
  //       <Route
  //         path="/:locale/:brand/"
  //         element={withSuspense(withRouterValidation(WorkingOnIt))}
  //       />
  //       <Route path="/" element={withSuspense(WorkingOnIt)} />
  //       <Route path="*" element={withSuspense(NotFound)} />
  //     </Routes>
  //   );
  // }
  return (
    <Routes>
      <Route path="/pay/:invoiceHash/:clientHash" element={withSuspense(Pay)} />
      <Route
        path="/:locale/:brand/success/:txtid"
        element={withSuspense(withRouterValidation(SuccessPlatform))}
      />
      <Route
        path="/:locale/:brand/pending/:txtid"
        element={withSuspense(withRouterValidation(PendingPlatform))}
      />
      <Route
        path="/:locale/:brand/error"
        element={withSuspense(withRouterValidation(ErrorViewPlatform))}
      />
      <Route
        path="/success/:invoiceHash/:clientHash/:txtid"
        element={withSuspense(Success)}
      />
      <Route
        path="/pending/:invoiceHash/:clientHash/:txtid"
        element={withSuspense(Pending)}
      />
      <Route
        path="/error/:invoiceHash/:clientHash"
        element={withSuspense(ErrorView)}
      />
      <Route
        path="/:locale/:brand/"
        element={withSuspense(withRouterValidation(FindInvoice))}
      />
      <Route path="/" element={withSuspense(FindInvoice)} />
      <Route path="*" element={withSuspense(NotFound)} />
    </Routes>
  );
}
