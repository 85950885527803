import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/payments\.venturatravel\.org/,
    /^https:\/\/payments-staging\.venturatravel\.org/,
  ],
});
